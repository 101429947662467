//
import { useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { setCookie, getCookie, deleteCookie } from 'lib/cookies'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import VideoJS from 'components/videoplayer'
import { videoJsOptions, handlePlayerReady } from 'components/videoconfig'

import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

import { Button } from '@material-tailwind/react'
import { Spinner } from '@material-tailwind/react'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { collectibleFormSchema } from 'lib/validations/collectible-form'

const LandingPage = () => {
  const location = useLocation()

  // On component mount, get 'id' query param and save as cookie
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get('id')
    if (id) {
      console.log('Setting affiliateId cookie:', id)
      setCookie('affiliateId', id, 7)
    }
  }, [location])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(collectibleFormSchema),
    mode: 'onSubmit',
  })

  const navigate = useNavigate()

  const mutation = useMutation(
    (data) =>
      axios.post(
        'https://ephsxkwo4a.execute-api.us-east-1.amazonaws.com/dev/funnel-1',
        {
          ...data,
          sourceSite: 'ybcs-collections-1',
        }
      ),
    {
      onSuccess: () => {
        reset()
        navigate('/success')
      },
      onError: (error) => {
        console.log('An error occurred during the mutation:', error)
      },
    }
  )

  const onSubmit = (data) => {
    // Get the affiliateId from the cookie and add it to the data
    const affiliateId = getCookie('affiliateId')
    console.log('Retrieved affiliateId cookie:', affiliateId)
    if (affiliateId) {
      data.affiliateId = affiliateId
    }

    // mutate the data
    mutation.mutate(data)
  }

  // Delete the affiliateId cookie when the mutation is successful
  useEffect(() => {
    if (mutation.isSuccess) {
      console.log('Deleting affiliateId cookie')
      deleteCookie('affiliateId')
    }
  }, [mutation.isSuccess])

  // video player
  const playerRef = useRef(null)
  // Use the imported functions
  const options = videoJsOptions(
    'https://d1v2h3l1a0mywl.cloudfront.net/collectible-promo.mp4'
  )
  const onReady = (player) => handlePlayerReady(player, playerRef)

  return (
    <>
      <div className="z-20 bg-[#0f172a]">
        <div className="background-gradient flex flex-col items-center pt-16">
          <div className="flex flex-col items-center ">
            <h1 className="font-monaEB text-3xl text-white md:text-5xl">
              Lock Your Spot Now!
            </h1>
            <h2 className="my-4 max-w-4xl px-2 text-center font-monaB text-xl text-white">
              <span className="sm:hidden md:inline-block">...and</span> Learn
              the Strategy Behind Building a Profitable Collectibles Empire
            </h2>
          </div>
          <div>
            <div className="mx-3 my-8">
              <VideoJS options={options} onReady={onReady} />
            </div>
            <div className="mt-8 flex flex-col items-center">
              <h3 className="font-monaB text-xl text-white">
                (Dozens joining DAILY!!!)
              </h3>
              <p className="mt-1 font-monaB text-xl text-white">
                Watch this video immediately
              </p>
            </div>
            {/* Form */}
            <div className="mx-3 my-24 rounded-lg border p-5 md:mx-auto md:w-[600px] md:p-10">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="bg-transparent"
              >
                <h1 className="mb-5 text-center text-2xl font-bold text-white">
                  Ready to claim your spot?
                </h1>
                <Label className="text-white" htmlFor="name">
                  First Name
                </Label>
                <Input
                  id="firstName"
                  placeholder="First Name"
                  type="text"
                  className="placeholder:text-grey-500 mb-4 mt-1 bg-white"
                  {...register('firstName')}
                />
                {errors.firstName && (
                  <p className="-mt-3 ml-1 text-xs text-red-500">
                    {errors.firstName.message}
                  </p>
                )}
                <Label className="text-white" htmlFor="name">
                  Last Name
                </Label>
                <Input
                  id="lastName"
                  placeholder="Last Name"
                  type="text"
                  className="placeholder:text-grey-500 mb-4 mt-1 bg-white"
                  {...register('lastName')}
                />
                {errors.lastName && (
                  <p className="-mt-3 ml-1 text-xs text-red-500">
                    {errors.lastName.message}
                  </p>
                )}
                <Label className="mt-5 text-white" htmlFor="name">
                  Email
                </Label>
                <Input
                  id="email"
                  placeholder="Email"
                  type="text"
                  className="placeholder:text-grey-500 mb-4 mt-1 bg-white"
                  {...register('email')}
                />
                {errors.email && (
                  <p className="-mt-3 ml-1 text-xs text-red-500">
                    {errors.email.message}
                  </p>
                )}
                <Label className="mt-5 text-white" htmlFor="name">
                  Phone #
                </Label>
                <Input
                  id="phone"
                  placeholder="Phone"
                  type="text"
                  className="placeholder:text-grey-500 mb-4 mt-1 bg-white"
                  {...register('phone')}
                />
                {errors.phone && (
                  <p className="-mt-3 ml-1 text-xs text-red-500">
                    {errors.phone.message}
                  </p>
                )}
                <div>
                  <h2 className="my-12 text-center text-lg text-white md:text-xl ">
                    You will receive a corporate website.
                    <span className="block py-1">The domain will be</span>
                    www.CollectDirect.com/YOURID
                  </h2>
                  <h3 className="my-5 text-center text-lg text-white">
                    What do you want your ID to be?
                  </h3>
                </div>
                <Label className="mt-5 text-white" htmlFor="name">
                  ID Choice 1:
                </Label>
                <Input
                  id="choice1"
                  placeholder="Choice 1"
                  className="placeholder:text-grey-500 mb-4 mt-1 bg-white"
                  {...register('choice1')}
                />
                {errors.choice1 && (
                  <p className="-mt-3 ml-1 text-xs text-red-500">
                    {errors.choice1.message}
                  </p>
                )}
                <Label className="mt-5 text-white" htmlFor="name">
                  ID Choice 2:
                </Label>
                <Input
                  id="choice2"
                  placeholder="Choice 2"
                  className="placeholder:text-grey-500 mb-4 mt-1 bg-white"
                  {...register('choice2')}
                />
                {errors.choice2 && (
                  <p className="-mt-3 ml-1 text-xs text-red-500">
                    {errors.choice2.message}
                  </p>
                )}
                <Button
                  type="submit"
                  className="mt-6 flex w-full items-center justify-center"
                >
                  {mutation.isLoading ? (
                    <>
                      <Spinner color="white" className="mr-3 h-5 w-5" />
                      One Moment...
                    </>
                  ) : (
                    'Submit Form'
                  )}
                </Button>
              </form>
            </div>
          </div>
        </div>
        <footer className="flex justify-center p-5">
          <p className="monaR text-xs text-white md:text-sm">
            Copyright © 2023 YBCS group. All rights reserved.
          </p>
        </footer>
      </div>
    </>
  )
}
export default LandingPage
