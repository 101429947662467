import * as React from 'react'
import VideoJS from 'components/videoplayer'
import { videoJsOptions, handlePlayerReady } from 'components/videoconfig'

const SuccessPage = () => {
  // video player
  const playerRef = React.useRef(null)
  // Use the imported functions
  const options = videoJsOptions(
    'https://d1v2h3l1a0mywl.cloudfront.net/lock-in-now.mp4',
    'https://d186srp7zxlrnx.cloudfront.net/mary&shawnthumbnail.png'
  )
  const onReady = (player) => handlePlayerReady(player, playerRef)

  return (
    <>
      <div className="relative z-20 flex h-screen flex-col bg-[#0f172a]">
        <div className="background-gradient flex-grow px-4 py-8">
          <div className="text-center">
            <h1 className="mt-12 font-monaEB text-3xl text-white md:text-5xl">
              Welcome Aboard !
            </h1>
            <h2 className="mx-auto my-4 max-w-4xl text-center font-monaB text-xl text-white">
              An email should be arriving shortly with further instructions
            </h2>
            <p className="mt-1 font-monaB text-lg text-white">
              but WAIT, before you go...MaryK and I have a special message for
              you
            </p>
          </div>
          <div className="mt-8 flex justify-center">
            <div className="w-full max-w-4xl">
              <VideoJS options={options} onReady={onReady} />
            </div>
          </div>
        </div>
        <footer className="mt-auto p-5 text-center">
          <p className="monaR mx-auto text-xs text-white md:text-sm">
            Copyright © 2023 YBCS group. All rights reserved.
          </p>
        </footer>
      </div>
    </>
  )
}

export default SuccessPage
