import videojs from 'video.js'

export const videoJsOptions = (src, posterUrl) => ({
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  poster: posterUrl,
  sources: [
    {
      src: src,
      type: 'video/mp4',
    },
  ],
})

export const handlePlayerReady = (player, playerRef) => {
  playerRef.current = player

  // You can handle player events here, for example:
  player.on('waiting', () => {
    videojs.log('player is waiting')
  })

  player.on('dispose', () => {
    videojs.log('player will dispose')
  })
}
