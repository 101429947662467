import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LandingPage from 'pages/LandingPage'
import SuccessPage from 'pages/SuccessPage'

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/success" element={<SuccessPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
