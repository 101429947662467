import * as z from 'zod'

export const collectibleFormSchema = z.object({
  firstName: z
    .string()
    .nonempty('Please enter your first name.')
    .min(2, 'Must be 2 or more characters long'),
  lastName: z
    .string()
    .nonempty('Please enter your last name.')
    .min(2, 'Must be 2 or more characters long'),
  email: z.string().email('Please enter a valid email address.'),
  phone: z.string().nonempty('Please enter a valid phone number'),
  choice1: z
    .string()
    .nonempty('This field is required.')
    .min(4, 'Must be 4 or more characters long'),
  choice2: z
    .string()
    .nonempty('This field is required.')
    .min(4, 'Must be 4 or more characters long'),
})
